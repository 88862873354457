<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullName }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.courses_bought.length }}
              </h5>
              <h5>Courses Bought</h5>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge v-if="userData.is_active" variant="light-success">Active</b-badge>
              <b-badge v-else variant="light-danger">Not Active</b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                  icon="PhoneOffIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Phone Verified</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge v-if="userData.verified == 'verified'" variant="light-success">Verified</b-badge>
              <b-badge v-else variant="light-danger">Not Verified</b-badge>
            </td>
          </tr>
<!--          <tr>-->
<!--            <th class="pb-50">-->
<!--              <feather-icon-->
<!--                icon="StarIcon"-->
<!--                class="mr-75"-->
<!--              />-->
<!--              <span class="font-weight-bold">Role</span>-->
<!--            </th>-->
<!--            <td class="pb-50 text-capitalize">-->
<!--              {{ userData.role }}-->
<!--            </td>-->
<!--          </tr>-->
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Referral Code</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.referral_code }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ userData.country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.phone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="CheckIcon" class="mr-75"/>
              <span class="font-weight-bold">Suspend/Activate</span>
            </th>
            <td>
              <status
                  @status_changed="changeStatus"
                  :row=userData
                  :module_be_base_url=module_base_url
              >
              </status>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  data() {
    return {
      module_base_url: "users",
      module_be_base_url: "users"
    }
  },
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BBadge,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    changeStatus(){
      if (this.userData.is_active == 1){
        this.userData.is_active = 0
      }else{
        this.userData.is_active =1;
      }
    }
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
